<template>
  <div v-if="unlock || isModIn">
    <div class="form-horizontal columns">
      <div class="form-group column col-6">
        <div class="col-4 col-sm-12">
          <label class="form-label" for="search-user">
            Lookup by AO3 username
          </label>
        </div>

        <div class="col-8 col-sm-12 input-group">
          <input
            class="form-input"
            type="text"
            id="search-user"
            placeholder="AO3 username"
            v-model="user"
            @keyup.enter="getUserPrompts(user)"
          />
          <button
            class="btn-secondary btn search-user-submit input-group-btn"
            @click="getUserPrompts(user)"
            @keyup.enter="getUserPrompts(user)"
          >
            Go!
          </button>
        </div>
      </div>
    </div>

    <h2 v-if="prompts.length">{{ prompts[0].username }}</h2>

    <template v-if="!loading && prompts.length">
      <span v-if="letter">
        <a :href="formatUrl(letter)" target="blank">Letter</a>  <span data-tooltip="If a request has HTML links in 
the optional details but no letter, 
the scraper interprets the last link
as a letter. Alas, this cannot be 
fixed due to the nature of AO3's
request page." class="tooltip c-hand"><sup><i aria-hidden="true" class="fa fa-exclamation-circle"></i></sup></span>
      </span>

      <table v-if="prompts.length">
        <thead>
          <tr>
            <th class="bookmark">
              <span class="fas fa-heart"></span>
            </th>
            <th class="fandom">Fandom</th>
            <th class="characters">Characters</th>
            <th>Prompt</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(prompt, index) in prompts"
            :key="index"
            :class="{ odd: index % 2 === 1, even: index % 2 == 0 }"
          >
            <td>
              <button class="bookmark" @click="togglePromptmark(prompt)">
                <span v-if="hasPromptmark(prompt)" class="fas fa-heart"></span>
                <span v-else class="far fa-heart"></span>
              </button>
            </td>
            <td>{{ prompt.fandom }}</td>
            <td>
              <ul v-if="prompt.characters" class="characters">
                <li v-for="c in prompt.characters" :key="c">{{ c }}</li>
              </ul>
              <span v-else>Any</span>
              <div class="divider"> </div>
            <span>{{ prompt.freeforms.join('') }}</span>
            </td>
            <td>
              <template v-if="prompt.prompt">
                <div v-html="prompt.prompt" class="prompt-text"></div>
                <p v-if="letter">
                  <a :href="formatUrl(letter)" target="blank">Letter</a>  <span data-tooltip="If a request has HTML links in 
the optional details but no letter, 
the scraper interprets the last link
as a letter. Alas, this cannot be 
fixed due to the nature of AO3's
request page." class="tooltip c-hand"><sup><i aria-hidden="true" class="fa fa-exclamation-circle"></i></sup></span>
                </p>
              </template>

              <p v-else-if="!prompt.prompt">
                No optional details given.
                <span v-if="prompt.letter">
                  However, they do have a
                  <a :href="formatUrl(prompt.letter)" target="blank">letter</a>.
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <div v-else-if="notFound">
      User not found! Search is case-sensitive and preserves whitespace - maybe
      check your search and try again?
    </div>
    <div v-if="loading" class="loading"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import utils from "../components/utils.js";
import axios from "axios";
export default {
  computed: {
    ...mapGetters(["promptmarks", "options", "unlock", "isModIn"])
  },
  data() {
    return {
      user: "",
      letter: "",
      prompts: [],
      loading: true,
      notFound: false
    };
  },

  beforeMount() {
    this.user = this.$route.params.id;
    this.getData();
  },
  watch: {
    $route() {
      this.user = this.$route.params.id;

      this.getData();
    }
  },
  methods: {
    ...utils,
    getData() {
      if (!this.user || !this.user.length) {
        this.loading = false;
        return;
      }
      axios
        .get(
          `https://tagset2023-default-rtdb.firebaseio.com/users/${this.user}.json`
        )
        .then(res => {
          if (res.data) {
            this.prompts = res.data;
            this.letter = this.prompts[0].letter;
          } else {
            this.prompts = [];
            this.notFound = true;
          }
          this.loading = false;
        });
    },
    getUserPrompts() {
      this.notFound = false;

      if (!this.user || !this.user.length) {
        this.loading = false;
        return;
      }

      if (this.$route.path !== `/user/${this.user}`) {
        this.$router.push({ path: `/user/${this.user}` });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
th.bookmark {
  width: 25px;
  color: black;

  span {
    cursor: initial !important;
  }
}

th.characters {
  width: 200px;
}
</style>
